<script setup lang="ts">
import { ComputedRef } from "vue";
import { Notification } from "@shopware-pwa/composables-next";

defineEmits<{
  (e: "click:close", id: number): void;
}>();

const props = defineProps<{
  notification: Notification;
}>();

const { formatLink } = useInternationalization();

const colorCssMap = {
  info: "border-l-maas-primary",
  success: "border-l-maas-brand-success",
  warning: "border-l-maas-brand-warning",
  danger: "border-l-maas-brand-danger",
};

const themeTypeColor: ComputedRef<string> = computed(
  () => colorCssMap[props.notification.type] || "maas-primary",
);
</script>
<template>
  <div
    v-if="notification.message.length > 0"
    :id="`toast-${notification.id}`"
    :data-testid="`notification-element-${notification.type}`"
    :class="`flex items-start justify-between gap-2 pointer-events-auto w-full max-w-[350px] p-4 bg-maas-background-default rounded-md shadow-xl border-px border-maas-border-grey-light border-l-10 ${themeTypeColor}`"
    role="alert"
  >
    <p
      data-testid="notification-element-message"
      class="ml-3 text-small text-maas-typography-text-grey-normal"
    >
      <NuxtLink v-if="notification.link" :to="formatLink(notification.link)">
        {{ notification.message }}
      </NuxtLink>
      <template v-else>
        {{ notification.message }}
      </template>
    </p>
    <button
      data-testid="notification-element-button"
      type="button"
      class="shrink-0 bg-maas-background-grey-medium flex h-5 w-5 items-center justify-center rounded-full text-[10px] sm:right-6 sm:top-6"
      :data-dismiss-target="`toast-${notification.id}`"
      aria-label="Close"
      @click="$emit('click:close', notification.id)"
    >
      <span class="sr-only">{{ $t("close") }}</span>
      ✕
    </button>
  </div>
</template>
